.kurobi-nftlanding {
  background-color: #010101;
  background-image: url(./assets/grid-bg.svg);
  min-width: 100vw;
  min-height: 100vh;
  color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  // overflow: clip;

  &__header {
    position: absolute;
    z-index: 4000;
    width: 100%;
    // inset: 0;
    left: 0;
    right: 0;
    top: 0;
    min-height: 60px;
    padding-top: 1.6rem;

    &-wrap {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0 1.6rem;
      max-width: 1280px;
    }

    &__logo {
      flex: 0 0 auto;
      display: flex;
      justify-content: flex-start;

      a {
        display: flex;
        justify-content: flex-start;

        img {
          width: 28%;

          @media screen and (max-width: 760px) {
            width: 88%;
            margin-left: 0.6rem;
          }
        }
      }
    }

    &__button {
      flex: 0 0 auto;
      display: flex;
      justify-content: flex-end;

      a.btn {
        background: transparent;
        border: .3px solid rgba(255, 255, 255, 0.515);
        padding: 0.5rem 1rem;
        height: 2.5rem;
        border-radius: 5px;
        color: #fff;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-weight: 600;
        opacity: 0.9;

        i {
          margin-left: 7px;
          font-size: 12px;
        }

        @media screen and (max-width: 760px) {
          margin-right: 5px;
        }

        &:active,
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  &__banner {
    padding-top: 9rem;

    @media only screen and (min-width: 1400px) {
      padding-top: 16.8rem;
    }

    @media screen and (max-width: 760px) {
      padding-top: 11.6rem;
    }

    &__content {
      position: relative;
      z-index: 300;
      margin: 0 auto;
      padding: 0 15px;

      &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        h2 {
          color: #fff;
          font-size: 100px;
          line-height: 112px;
          margin-bottom: 2rem;
          font-family: "Fractul Alt";
          font-family: Neue Machina;
          font-weight: 500;
          max-width: 800px;
          background: -webkit-linear-gradient(right, #fbc124, #fff);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          letter-spacing: -.02em !important;
          position: relative;
          z-index: 3000;
          // animation: textAminate 8s linear infinite;
          // background-size: 800% 800%;

          @media screen and (max-width: 760px) {
            font-size: 64px;
            line-height: 68px;
          }
        }

        p {
          margin-bottom: 2.4rem;
          max-width: 764px;
          line-height: 35px;
          font-size: 1.3rem;
          font-weight: 400;
          opacity: .88;
          font-family: Neue Machina;
          position: relative;
          z-index: 3000;

          @media screen and (max-width: 760px) {
            line-height: 30px;
            font-size: .99rem;
          }
        }

        a.btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 165px;
          height: 48px;
          padding: 16px 24px;
          border-radius: 26px;
          border: 0;
          font-size: 1.12rem;
          font-weight: 600;
          cursor: pointer;
          transition: background-color .15s linear,
            color .15s linear;
          white-space: nowrap;
          letter-spacing: -.02em !important;
          opacity: .7;
          pointer-events: none;
          background: #392b0957;
          position: relative;
          z-index: 3000;

          span {
            background: -webkit-linear-gradient(right, #fbc124, #fff);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .start {
          padding-right: 60px;

        }

        #lottie-anim-love {
          width: 22px;
          position: absolute;
          top: 43px;
          height: auto;
          left: 277px;
          z-index: 5000;

          @media only screen and (min-width: 1400px) {
            top: 40px;
            left: 280px;
          }

          @media screen and (max-width: 760px) {
            top: 26px;
            left: initial;
            right: 96px;
            width: 16px;
            display: none;
          }

        }
      }

      &__image {
        position: absolute;
        width: 16%;
        z-index: 200;

        &-switch {
          top: 3px;
          left: 276px;
          width: 13%;

          @media only screen and (min-width: 1400px) {
            left: 280px;
            width: 12%;
            top: 5px;
          }

          @media screen and (max-width: 760px) {
            top: 1px;
            left: 234px;
            width: 20%;
          }
        }

        &-sparkle {
          width: 100%;
          top: -23px;
          opacity: .34;
        }

        &-one {
          top: 18px;
          left: -70px;
          width: 13%;
          animation: pulse 5s infinite ease-in-out;
        }

        &-two {
          top: -24px;
          right: -20px;
          width: 10%;

        }

        &-three {
          top: 124px;
          right: 64px;
          width: 12%;
          animation: rotation 20s infinite linear;

          @media screen and (max-width: 760px) {
            top: 149px;
            right: 147px;
            width: 12%;
            z-index: 4000;
          }
        }

        &-four {
          top: 118px;
          left: 29px;
        }
      }
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, #010101, #00000052 85%);
    z-index: 1;
  }
}

.background-glow {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.background-glow__wrap {
  width: 100%;
  // position: relative;
  height: 100%;
  filter: blur(240px);
}

.background-glow__one {
  position: absolute;
  left: -63px;
  top: -166px;
  width: 500px;
  height: 500px;
  /* margin-top: -250px; */
  /* margin-left: -250px; */
  border-radius: 100%;
  background-color: #ecc25d1c;
}

// .background-glow__two {
//   position: absolute;
//   border-radius: 362.53px;
//   transform: rotate(-35.6deg);
//   mix-blend-mode: overlay;
//   right: 261px;
//   background: radial-gradient(65.67% 65.67% at 41.2% 34.33%,
//       #094bada1 40%,
//       #1ba0f8b9 100%);
//   top: 200px;
//   width: 500px;
//   height: 500px;
// }

.grained {
  position: initial !important;
}

// .background-glow__three {
//   position: absolute;
//   left: 290px;
//   top: 218px;
//   width: 500px;
//   height: 500px;
//   border-radius: 100%;
//   z-index: 4;
//   background: radial-gradient(65.67% 65.67% at 41.2% 34.33%, #030818 40%, #030409 100%);
// }


// span {
//   background: linear-gradient(270deg,
//       #9f2fff,
//       #5c34de,
//       #de0ba8,
//       #6baeec);
//   animation: textAminate 8s linear infinite;
//   background-clip: text;
//   -webkit-text-fill-color: transparent;
//   background-size: 800% 800%;
// }


@keyframes textAminate {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}