.kurobi-roadmap {
  &__hero {
    background-color: #fffaee;
    // background-image: url(../images/bg-dot-left.svg),
    // url(../images/bg-dot-2.svg),
    // url(../images/bg-dot-right.svg);
    // background-position: -5% 100%,130% -18%, 101% 100%;
    // background-repeat: no-repeat;
    // background-size: 26%,44%,37%;
    // background-repeat: no-repeat;
    padding-top: 4rem;
    min-height: 28rem;
    display: flex;
    color: #17173a;
    position: relative;
    overflow: clip;

    @media screen and (max-width: 760px) {
      min-height: 14rem;
      padding: 2.8rem 0.8rem 2rem;
    }

    &__text {
      z-index: 90;
      position: relative;

      h5 {
        font-size: 12px;
        line-height: 120%;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-family: 'Gilroy-Medium',
          sans-serif;
      }

      h3 {
        font-family: 'Gilroy-medium',
          sans-serif;
        font-size: 2.1rem;
        margin: 10px 0 14px;
        max-width: 520px;
        line-height: 45px;

        @media screen and (max-width: 760px) {
          font-size: 24px;
          line-height: 30px;
        }
      }

      h6 {
        max-width: 503px;
        opacity: .89;
        // letter-spacing: -0.01rem;
        font-size: 21px;
        line-height: 34px;
        font-family: 'Gilroy-regular',
          sans-serif;

        @media screen and (max-width: 760px) {
          font-size: 17px;
          line-height: 30px;
        }

        span {
          font-family: 'Gilroy-medium',
            sans-serif;
          // background: linear-gradient(135deg, #cea642 32.5%, #f6e0a8 100%);
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          -webkit-text-stroke: 0.1px #fbc124;
        }
      }
    }

    &__image {
      position: absolute;

      &-one {
        top: 1px;
        left: -1.5rem;
        width: 47%;
        z-index: 70;
      }

      &-two {
        bottom: -14px;
        right: -3.5rem;
        width: 49%;
        z-index: 70;
      }

      &-three {
        width: 20%;
        left: 41%;
        top: 100px;
        z-index: 60;
      }

      &-four {
        width: 15%;
        left: 14%;
        opacity: .92;
        top: 40px;
        z-index: 60;
      }

      &-five {
        width: 12%;
        right: 14%;
        opacity: .95;
        top: 74px;
        z-index: 50;
      }

      &-six {
        width: 6%;
        right: 37%;
        opacity: .95;
        top: 61px;
        z-index: 50;
      }

      &-seven {
        width: 12%;
        right: 42%;
        opacity: .95;
        top: 60%;
        z-index: 57;
      }
    }
  }

  &__content {
    // background: #f6f2e4;
    background: #fffaee;
    z-index: 90;
    position: relative;

    &__wrap {
      background: #ffffffb5;
      padding: 1.6rem 2.8rem 2rem;
      margin-top: -8rem;
      z-index: 100;
      position: relative;
      border-radius: 21px;
      backdrop-filter: blur(5px);

      @media screen and (max-width: 760px) {
        padding: 0.4rem 1.2rem 2rem;
        margin-top: -1.2rem;
      }
    }

    &__text {
      color: #17173a;
      margin-bottom: 1.2rem;

      &__title {
        h2 {
          font-family: "Gilroy-medium";
          font-size: 16px;
          margin: 32px 0 8px;
          background: #0f1218;
          color: #fbc124;
          width: clamp(7.2rem, 5vw, 9rem);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.8rem 1.2rem;
        }

      }

      &__list {
        ul {
          list-style: none;
          padding-left: 2.4rem;

          li {
            padding: 1rem 0 1.2rem;
            border-bottom: 0.8px solid #0f1218;
            position: relative;


            p {
              font-size: 17px;
              line-height: 32px;
              font-family: Averta-Regular,
                arial,
                sans-serif;
              margin: 0;
              padding: 0;

              &::before {
                content: url("./assets/check.svg");
                display: block;
                position: absolute;
                top: 20px;
                left: -26px;
              }
            }

            &.complete {
              p {
                &::before {
                  content: url("./assets/complete-check.svg");
                  bottom: 18px;
                }

              }
            }
          }
        }
      }

    }
  }
}