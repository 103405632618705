  .buttons {
    margin-top: 3rem;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      flex-wrap: wrap;
    }

    button {
      background: #fff8eb;
      color: #141928;
      height: 52px;
      display: flex;
      width: 153px;
      justify-content: space-around;
      align-items: center;
      border: none;
      padding: 9px;
      margin-right: 2rem;
      border-radius: 5px;
      text-decoration: none;

      @media only screen and (max-width: 600px) {
        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: 2rem;
        width: 143px;
      }

      .android-icon {
        color: #59ff59;
        font-size: 46px;
      }

      .playstore-icon {
        width: 35px;
      }

      div {
        margin-right: 1rem;
      }

      svg {
        font-size: 35px;
      }

      p {
        margin-bottom: 8px;
        font-size: 7px;
        margin-top: 8px;

        &.store {
          font-size: 13px;
          margin-top: -11px;

          @media only screen and (max-width: 600px) {
            font-size: 12px;
          }
        }
      }

      &.early-access {
        background: transparent;
        border: 1px solid #fff8eb;
        color: #fff8eb;
        width: 12rem;

        svg {
          font-size: 20px;
        }
      }
    }
  }