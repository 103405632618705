.kurobi-token {
  &__countdown {
    display: flex;
    gap: 0 1rem;

    @media only screen and (max-width: 750px) {
      gap: 0 .2rem;
    }

    &-item {
      p {
        background: #0e1219;
        border: none;
        padding: .5rem 1rem;
        height: 2.5rem;
        border-radius: 5px;
        color: #fff8eb;
        font-size: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: .5rem;
        min-width: 55px;
      }
    }
  }

  &__header {
    background: #fffaee;
    min-height: 20rem;
    align-items: center;
    display: flex;
    color: #17173a;
    // margin-bottom: 3rem;
    position: relative;
    padding: 3rem 0 3rem;

    h5 {
      font-family: "Gilroy-medium",
        sans-serif;
      font-size: 2rem;
      margin: 12px 0 28px;
      max-width: 350px;
      line-height: 40px;
    }

    p {
      font-size: 20px;
      line-height: 30px;
      font-family: "Averta-Regular",
        sans-serif;
      max-width: 400px;
      opacity: 0.85;
    }

    &__curve {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      z-index: 20;
      width: 100%;
      margin: 0 auto;
    }

    &__button {
      .btn {
        background: #fbc124;
        font-size: .9rem;
        font-weight: 600;
        border-radius: 8px;
        padding: 0.6rem 1.2rem;
        outline: none;
        border: none;

        &:hover,
        &:focus,
        &:active,
        &:visited {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    }
  }

  &__content {
    background: #fffdfb;

    @media only screen and (max-width: 750px) {
      overflow: hidden;
    }

    &__tables {
      margin-bottom: 2rem;

      &-wrap {
        margin: 1.5rem 0;
      }

      &__table {
        table-layout: fixed;
        overflow-x: scroll;

        h4 {
          font-family: "Gilroy-medium", sans-serif;
          font-size: 1.2rem;
          margin-bottom: 1.7rem;
          position: relative;

          &::after {
            content: url('./assets/thin-marker-stroke.svg');
            position: absolute;
            left: 0;
            bottom: -15px;
          }
        }

        .table {
          border: 1px solid #ccc5b28a;
          color: #17173a;

          &> :not(caption)>*>* {
            border: 1px solid #ccc5b28a;
            padding: 0.8rem 1.5rem;
          }

          // &> :not(:last-child)> :last-child>* {
          //   // border: none;
          // }

          thead {
            tr {
              th {
                font-family: "Gilroy-medium",
                  sans-serif;
                font-size: .9rem;
                opacity: .6;
                border-bottom: none;
              }

              td {
                font-family: "Averta-Regular", sans-serif;
              }
            }
          }

          tbody {
            tr {
              th {
                font-family: "Gilroy-bold",
                  sans-serif;
                font-size: 0.9rem;
                font-weight: normal;
              }

              td {
                font-family: "Averta-Regular",
                  sans-serif;
                font-size: 1rem;
                opacity: .9;

                p {
                  font-size: 16px;
                  max-width: 250px;
                  line-break: anywhere;
                  word-break: break-all;
                }

                a {
                  font-size: 14px;
                  text-decoration: none;
                  color: #7b663f;
                }
              }
            }
          }

          &-striped {
            &>tbody>tr:nth-of-type(2n + 1) {
              --bs-table-accent-bg: #fffaef5e;
              color: var(--bs-table-striped-color);
            }
          }
        }
      }
    }

    &__distribution {

      &.first,
      &.two,
      &.three {
        // background: #fffdfb;
        // padding: 1rem 0;

        @media only screen and (max-width: 750px) {
          padding-top: 1rem;
        }
      }

      // .three {}

      &__item {
        padding: 1rem 3rem;
        background: #fff;

        &__text {
          font-family: "Averta-Regular";

          h5 {
            font-family: "Gilroy-medium",
              sans-serif;
            font-size: 1.3rem;
            margin: 12px 0 28px;
            line-height: 36px;
            position: relative;

            &::after {
              content: url('./assets/thin-marker-stroke.svg');
              position: absolute;
              left: 3px;
              bottom: -15px;

              @media only screen and (max-width: 750px) {
                left: -5px;
              }
            }
          }

          p {
            font-size: 16px;
            max-width: 775px;
            opacity: .8;
            line-height: 32px;

            @media only screen and (max-width: 768px) {
              word-break: break-word;
            }
          }



          ul {
            list-style: none;
            font-family: "Averta-Regular", sans-serif;
            padding-left: 1rem;

            li {
              font-size: 18px;
              margin-bottom: 1.8rem;
              position: relative;
              line-height: 32px;
              position: relative;
              z-index: 20;

              &::before {
                background: transparent;
              }

              // span {}

              ul {
                list-style: disc;
                margin: 1.5rem 0 2rem;
              }
            }
          }

          &>ul {
            &>li {
              span {
                font-family: "Gilroy-bold",
                  sans-serif;
                font-size: 1.1rem;
                font-weight: normal;
                margin-right: .3rem;
              }

              &::before {
                content: "";
                position: absolute;
                display: block;
                min-height: 30px;
                width: 30px;
                border-radius: 50%;
                background: #fbc12421;
                left: -9px;
                z-index: 50;
              }

              // &:nth-of-type(even) {
              //   &::before {
              //     background: #00000021;
              //   }
              // }

              h2 {
                font-family: "Gilroy-medium",
                  sans-serif;
                font-size: 1.4rem;
                line-height: 40px;
                margin: 36px 0 8px;
              }
            }

          }



          margin-bottom: 1rem;
        }

        &__image {
          height: 100%;
          background: #fffdfb;
          display: flex;
          justify-content: center;
          border-radius: 43px;
          position: relative;
          // padding: 2.5rem;
        }
      }
    }

    &__figures {
      background: #fffdfb;

      &-wrap {
        padding: 2rem 0 3rem;

        &>.row {
          justify-content: center;

          @media only screen and (max-width: 768px) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: scroll;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }

      &__block {
        background: #fdf1d0;
        text-align: center;
        padding: 3rem 2rem 3rem;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 768px) {
          margin: .2rem .2rem 2rem;
        }

        h4 {
          font-family: "Gilroy-medium",
            sans-serif;
          font-size: 1.4rem;

          @media only screen and (max-width: 750px) {
            font-size: 1.1rem;
          }
        }

        p {
          opacity: .9;
          font-family: "Gilroy-medium",
            sans-serif;
        }

      }
    }

    &__intro {

      &__item {
        font-family: "Averta-Regular";
        background: #fff;
        padding: 3rem 3rem 0rem;

        &__text {
          strong {
            font-size: 18px;
            opacity: .9;
            font-family: "Gilroy-bold",
              sans-serif;
            font-weight: inherit;
            margin-right: .8rem;
          }

          p {
            font-size: 16px;

            @media only screen and (max-width: 768px) {
              word-break: break-word;
            }
          }

          a {
            font-size: 14px;
            margin-left: 15px;
            text-decoration: none;
            color: #7b663f;
          }
        }
      }

    }

  }

  &__modal {
    .modal {
      &-content {
        background: #fffaee;
      }

      &-header {
        border: none;
        text-align: center;
        justify-content: center;

        h5 {
          font-family: "Gilroy-medium",
            sans-serif;
          font-size: 1.6rem;
          margin: 12px 0 8px;
          line-height: 40px;
        }
      }
    }

    &__dex {
      h4 {
        font-family: "Gilroy-medium",
          sans-serif;
        font-size: 1.6rem;
        margin: 20px 0 24px;
        line-height: 40px;
        display: flex;
        justify-content: center;
      }

      &__steps {
        &-wrap {
          background: #f9f1df;
          padding: 2rem 2rem;
          border-radius: 8px;
        }

        &__item {
          display: flex;
          align-items: center;
          gap: 0 20px;
          margin-bottom: 1.6rem;

          &__number {
            span {
              border-radius: 100%;
              background: #f7e9cc;
              width: 52px;
              height: 52px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.2rem;
            }
          }

          &__text {
            h6 {
              font-family: "Averta-Regular",
                sans-serif;
              font-size: 1rem;
              line-height: 1.6rem;

              a {
                color: #c59a27;
                text-decoration: none;
              }

            }
          }
        }

      }
    }
  }
}