.footer {
  padding: 3rem 0 1rem;
  background: #0e1219;

  @media only screen and (max-width: 600px) {
    padding: 2rem 0;
  }

  a {
    text-decoration: none;
  }

  h4 {
    font-size: 31px;
    color: #fff8eb;
  }

  h6 {
    font-size: 17px;
    line-height: 175%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #fff;
    opacity: 0.82;
    font-weight: 300;
    max-width: 400px;

    @media only screen and (max-width: 600px) {
      font-size: 17px;
      max-width: 350px;
      margin-top: 23px;
      opacity: 0.7;
    }
  }

  a {
    text-decoration: none;
  }

  .footer-row {
    position: relative;
    align-items: baseline;
    padding-bottom: 2rem;
    color: #fff8eb;

    p {
      @media only screen and (max-width: 600px) {
        font-size: 12px;
        text-align: center;
        margin-top: 0.8rem;
        opacity: 0.6;
      }
    }

    @media only screen and (max-width: 600px) {
      top: 30px;
    }

    ul {
      justify-content: flex-end;
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      align-items: baseline;

      @media only screen and (max-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 2rem 0;
        margin-bottom: 3rem;
      }

      li {
        margin-left: 1.5rem;

        @media only screen and (max-width: 600px) {
          justify-self: center;
          align-self: center;
          margin-left: 0;
          place-content: center;
          margin-bottom: 0;
        }

        a {
          color: #fff8eb !important;
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }

    .buttons-col {
      @media only screen and (max-width: 600px) {
        // justify-content: center;
        margin-bottom: 1.5rem;
      }

      .wallet-button {
        margin-right: 1rem;
      }
    }
  }

  &__social-links {
    @media only screen and (max-width: 600px) {
      gap: 1rem 2rem;
    }

    a {
      color: #fffaee;
      font-size: 1.5rem;
      margin: 0.5rem 1.7rem 0.5rem 0;

      @media only screen and (max-width: 600px) {
        margin: 0;
      }

      &:hover {
        color: #f0e7d2;
      }
    }
  }
}
