.header {
  background: #ffc02c1c;
  padding: 2.0rem 0 1rem;
  position: sticky;
  z-index: 6000;
  top: 0;
  backdrop-filter: saturate(180%) blur(20px);


  @media only screen and (max-width: 600px) {
    padding-top: 1rem;
  }

  ul {
    display: flex;
    align-items: center;
    padding: 0;
    font-family: "Gilroy-Medium", sans-serif;
    margin-bottom: 0;

    @media only screen and (max-width: 600px) {
      // margin-left: -5rem;
    }

    a {
      text-decoration: none;
    }

    li {
      list-style-type: none;
      font-weight: 400;
      //   font-size: 15px;
      //  color: #17173A;
      margin-right: .8rem;

      @media only screen and (max-width: 600px) {
        margin-left: 5px;
        font-size: 12px;
      }

      a {
        color: #0e1219;
        cursor: pointer;
        text-decoration: none;

        /* Portrait and Landscape */
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
          font-size: .8rem;
        }

        &:hover {
          color: #0e1219a6;
        }
      }

      button {
        color: #0e1219;
        cursor: pointer;
        text-decoration: none;
        background: transparent;
        border: none;
      }
    }
  }

  .buttons-col {
    font-size: 13px;

    @media only screen and (max-width: 600px) {
      display: flex;
      // margin-top: 1rem;
      justify-content: flex-end;
    }

    ul {
      li {
        margin: 0 0 0 1rem;

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
          margin: 0;
        }
      }
    }

    .wallet-button {
      border: 1px solid #f7f4f4;
      border-radius: 4px;
      padding: 8px;
      width: 9rem;
      margin-right: 2rem;
      color: #525151;
      background: #f9f7f7;
    }

    .invite-button {
      background: #0e1219;
      border: none;
      padding: 0.5rem 1rem;
      height: 2.5rem;
      border-radius: 5px;
      color: #fff8eb;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;

      /* Portrait and Landscape */
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        padding: 0.2rem 0.4rem;
      }

      i {
        color: #0e1219;
        background: #fff8eb;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        font-size: 0.65rem;
        padding: 0;
        margin-left: 8px;
      }

      @media only screen and (max-width: 600px) {
        margin-left: 0;
      }
    }

    .launch-button {
      background: transparent;
      border: 1.5px solid #0e1219;
      padding: 8px;
      width: 9rem;
      border-radius: 4px;
      color: #0e1219;
      margin-left: 1rem;
      font-weight: 700;
      font-family: "Gilroy-Medium", sans-serif;
      height: 2.4rem;

      @media only screen and (max-width: 600px) {
        margin-left: 0;
      }
    }

    a {
      text-decoration: none;
    }
  }

  .row {
    align-items: baseline;

    .kurobi-logo {
      font-size: 1.4rem;
      font-family: "Gilroy-Medium", sans-serif;

      @media only screen and (max-width: 600px) {
        font-size: 1.2rem;
      }

      a {
        color: #0e1219;
        text-decoration: none;
        flex-wrap: nowrap;
        display: flex;
        align-items: center;

        /* Portrait and Landscape */
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
          font-size: 1rem;
        }

        :hover {
          text-decoration: none;
        }
      }

      img {
        margin-right: 0.7rem;
        width: 2.6rem;

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
          width: 1.6rem;
        }
      }

      @media only screen and (max-width: 600px) {
        margin: 0;
      }
    }
  }

  .mobile-nav-button {
    button.btn {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .mobile-nav-menu {
    .offcanvas {
      &-start {
        width: 300px;
        z-index: 50000;
        background: #fffaee;
        min-height: 100vh;
      }

      &-title {
        a {
          color: #0e1219;
          text-decoration: none;
          font-weight: 700;

          img {
            width: 17%;
            margin-right: 0.7rem;
          }
        }
      }

      &-body {

        ul {
          align-items: flex-start;
        }

      }
    }

    .modal-backdrop {
      z-index: 30000;
    }

    ul.nav {
      li {
        margin-bottom: 1.2rem;

        a {
          font-size: 1.1rem;
          text-transform: capitalize;
        }
      }
    }
  }
}


.nav-menu-dropdown a {
  display: flex;
  align-items: center;
}

.nav-menu-dropdown .dropdown-menu {
  display: none;
  min-width: 326px;
  left: -55px !important;
  min-height: 200px;
  top: 35px !important;
  border: none;
  background: #fff;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  padding: .6rem .3rem .84em;
  border-radius: 11px;
  gap: .9rem 0;
}

.nav-menu-dropdown .dropdown-menu:before {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;
  width: 1rem;
  height: 1rem;
  transform: translateX(calc(-50% - 24.0312px)) rotate(45deg);
  border-top-left-radius: 4px;
  background: #ffffff;
  will-change: transform;
  transition-property: transform;
  z-index: 30;
}


/* .nav-menu-dropdown .dropdown-menu.show {
  display: grid;
} */


.nav-menu-dropdown:focus-within>.dropdown-menu,
.nav-menu-dropdown:hover>.dropdown-menu {
  display: grid;

}

.nav-menu-dropdown>.dropdown-toggle {
  pointer-events: none;
}


.nav-menu-dropdown a.dropdown-item {
  display: flex;
  padding: .2rem 1.3rem;
  gap: 0 1rem;
  position: relative;
  border-radius: 9px;
}

.nav-menu-dropdown .dropdown-item:hover,
.nav-menu-dropdown .dropdown-item:focus {
  color: #17294d;
  background-color: #fdfaf8;
}

.nav-menu-dropdown a.dropdown-item .links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.nav-menu-dropdown a.dropdown-item .menu-image {
  /* background: #fdf7f4; */
  background: transparent;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  will-change: background;
  transition: background .4s ease-in-out;
}


.nav-menu-dropdown a.dropdown-item .menu-image img {
  width: 50px;
}


// .nav-menu-dropdown .dropdown-item:hover .menu-image,
// .nav-menu-dropdown .dropdown-item:focus-within .menu-image {
//   background: #fff;
// }

.nav-menu-dropdown a.dropdown-item .links span:first-child {
  font-weight: 500;
  font-size: 1rem;
  font-family: 'averta-semibold',
    arial,
    sans-serif;
  letter-spacing: .04rem;
}

.nav-menu-dropdown a.dropdown-item .links span:last-child {
  font-family: "Averta-Regular";
  font-size: .93rem;
  margin-top: .25rem;
}

.nav-menu-dropdown a.dropdown-item.has-heading header {
  position: absolute;
  top: -10px;
  display: block;
}

.nav-menu-dropdown a.dropdown-item.has-heading header h4 {
  text-transform: uppercase;
  font-family: "AvertaStd-Semibold";
  font-size: .75rem;
  opacity: .7;
}