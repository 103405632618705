.register {
  background: #0e1219;
  height: 100vh;
  padding: 7rem 13rem;
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: 1800px) {
    padding: 10rem 13rem;
  }

  @media only screen and (max-width: 600px) {
    padding: 8rem 1.5rem 0;
  }

  &__logo {
    position: absolute;
    top: 40px;
    left: 48%;

    @media only screen and (max-width: 600px) {
      top: 29px;
      left: 6%;
    }

    img {
      height: 60px;
    }
  }

  h2 {
    color: white;
    font-weight: 600;
    font-size: 2.3rem;
    max-width: 33rem;
    line-height: 52px;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 600px) {
      width: initial;
      font-size: 2rem;
      margin-bottom: 1rem;
      line-height: 45px;
      text-align: left;
    }
  }

  p {
    color: #fff;
    font-size: 1.19rem;
    opacity: .7;

    span {
      opacity: 1 !important;
      color: #fdefc4;
    }

    @media only screen and (max-width: 600px) {
      text-align: left;
    }
  }


  .registration-section {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    @media only screen and (max-width: 600px) {
      display: block;
      padding: .5rem;
    }

    input {
      width: 20rem;
      height: 50px;
      font-size: 14px;
      background: transparent;
      color: white;

      @media only screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: 1rem;
      }

      &:focus {
        border-color: #fdefc38c;
        outline: 0;
        box-shadow: 0 0 0 0.25rem #faebd763;
      }
    }

    button {
      margin-left: 8px;
      font-size: 14px;
      width: 9rem;
      background: #feeec3;
      border: none;
      color: #141a28;

      @media only screen and (max-width: 600px) {
        width: 100%;
        height: 50px;
        margin-left: 0;
      }


      &:focus {
        box-shadow: none !important;
      }

      .loader {
        width: 30px !important;
        height: 30px !important;
        color: #141a28 !important;
      }
    }
  }

  &__illustration {
    width: 100%;
    position: relative;

    @media only screen and (min-width: 1500px) {
      left: 36%;
      bottom: -280px;
    }

    @media only screen and (max-width: 720px) {
      left: -20%;
      bottom: -41%;
      display: none;
    }

    img {
      height: 600px;
      position: fixed;
      bottom: -300px;
      left: 33%;
    }

    .feat-slab {
      position: relative;
      display: flex;

      // &::after {
      //   content: url("./assets/feat-bg-alt.svg");
      //   display: block;
      //   // background: #FFFAEE;
      //   // width: 515px;
      //   // height: 720px;
      //   position: absolute;
      //   bottom: -250px;
      //   left: -254px;

      //   @media only screen and (max-width: 600px) {
      //     display: none;
      //   }
      // }
    }

    .features {
      &-image {
        position: relative;
        display: flex;
        // height: 100%;
        mask-image: -webkit-radial-gradient(#fff, #000);

        img {
          width: 100%;
          height: 100%;
        }

        &-wrap {
          // width: 264px;
          // height: 533px;
          padding: 9px;
          background: #262f4a;
          box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
            0 30px 60px -30px rgba(0, 0, 0, 0.3),
            inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);
          -webkit-user-select: none;
          user-select: none;
          font-size: 16px;
          position: relative;
          width: 301px;
          height: 615px;
          border-radius: 42px;
          z-index: 100;
          margin-left: -90px;

          @media only screen and (max-width: 720px) {
            margin-left: initial;
            padding: 10px;
          }
        }
      }

      &-words {
        width: 92%;
        position: absolute;
        top: 346px;
        background: #fff;
        padding: 20px;
        height: 250px;
        border-radius: 10px 10px 30px 30px;
        text-align: left;

        @media only screen and (max-width: 720px) {
          width: 94%;
        }

        h3 {
          font-size: 16px;
          color: #34324e;
          line-height: 24px;
          margin-bottom: 0.5rem;
          text-align: left;
        }

        p {
          font-weight: 400;
          color: #34324ed9;
          font-size: 12px;
          opacity: 0.8;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          margin-right: 0.5rem;
        }

        &-user {
          display: flex;
          align-items: center;
          margin-bottom: 0.4rem;

          &-avatar {
            display: block;
            height: 30px;
            width: 30px;
            background: #eff2f7;
            border-radius: 50%;
          }

          &-name {
            margin-bottom: 0;
            font-weight: 400;
            margin-left: 5px;
          }
        }

        &-button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 22px;

          button {
            width: 48%;
            border: none;
            outline: none;
            font-size: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0;
            border-radius: 4px;
            color: #34324e;
            pointer-events: none;

            &.main {
              background: #ffc02c;
            }

            &.outline {
              border: 1px solid #dde4f0;
              background: transparent;
            }

            i {
              margin-right: 5px;
            }
          }
        }

        .link {
          color: #34324e;
          font-size: 13px;
          border: 1px solid #dde4f0;
          padding: 0.4rem 0.6rem;
          border-radius: 4px;
        }
      }
    }

  }
}