.kurobi-legal {
  &__header {
    background: #fffaee;
    min-height: 20rem;
    align-items: center;
    display: flex;
    color: #17173a;
    margin-bottom: 3rem;

    h5 {
      font-size: 12px;
      line-height: 120%;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-family: 'Gilroy-Medium',
        sans-serif;
    }

    h3 {
      font-family: 'Gilroy-medium', sans-serif;
      font-size: 2.1rem;
      margin: 12px 0 20px;
      max-width: 520px;
      line-height: 45px;
    }

    h6 {
      font-size: 12px;
      line-height: 120%;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: capitalize;
      font-family: 'Gilroy-Medium',
        sans-serif;
    }
  }

  &__content {
    color: #17173a;
    margin-bottom: 4rem;

    h2 {
      font-family: "Gilroy-Medium";
      font-size: 22px;
      margin: 25px 0 17px;
    }

    h4 {
      font-family: "Gilroy-Medium";
      font-size: 26px;
      margin: 25px 0 35px;
    }

    p {
      font-size: 15px;
      line-height: 31px;
      opacity: .9;
    }

    ul {
      // list-style: none;

      li {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 11px;
      }
    }
  }
}