.landing-page {
  scroll-behavior: smooth;

  .container {
    max-width: 1200px;
  }

  .section-one {
    padding: 3rem 0 2.8rem;
    background: #ffc02c1c;

    @media only screen and (max-width: 768px) {
      padding: 0rem 0 5rem;
      min-height: 82vh;
    }

    /* Portrait and Landscape */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      padding: 1rem 0 2.8rem;
      min-height: 72vh;
    }


    .section-one-text {
      padding: 2rem 0rem;

      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        padding: 2rem 1rem;
      }

      @media only screen and (max-width: 768px) {
        padding: 3rem 1rem 1rem;
      }

      h6 {
        margin-bottom: 25px;
        color: #0e1219;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.001rem;
        text-transform: uppercase;
        font-family: 'Gilroy-Medium',
          sans-serif;

        @media only screen and (max-width: 768px) {
          margin-bottom: 10px;
          font-size: 15px;
          line-height: 1.2;
        }
      }

      h1 {
        // font-weight: 500;
        // line-height: 70px;
        // color: #0e1219;
        // letter-spacing: -0.07rem;
        // max-width: 461px;
        // font-size: 48px;
        // margin-bottom: 21px;
        font-weight: 500;
        line-height: 54px;
        color: #0e1219;
        letter-spacing: -0.07rem;
        max-width: 800px;
        font-size: 35px;
        margin-bottom: 21px;
        font-family: 'Gilroy-Medium',
          sans-serif;
        word-spacing: -0.5px;
        opacity: 0;

        strong {
          color: #2b2c34;
          font-family: 'Gilroy-bold',
            sans-serif;
          background: linear-gradient(90deg,
              rgba(255, 192, 44, 0),
              rgba(255, 192, 44, 0.43));
          letter-spacing: -0.075rem;
          padding-right: 1rem;
          border-radius: 0 6.5rem 6.5rem 0;
          // margin-right: -0.25rem;
        }

        span {
          box-shadow: #ffedc3 0px -17px 0px inset;
        }



        @media only screen and (max-width: 768px) {
          font-size: 24px;
          line-height: 44px;
          margin-top: 20px;
          margin-bottom: 15px;
        }

        /* Portrait and Landscape */
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
          font-size: 24px;
          line-height: 37px;
          margin-top: 20px;
          margin-bottom: 15px;
          max-width: 100%;
        }
      }

      .kurobi-text {
        font-size: 20px;
        line-height: 32px;
        color: #0e1219;
        opacity: 0.84;
        font-weight: 300;
        max-width: 405px;
        display: block;
        opacity: 0;

        @media only screen and (max-width: 600px) {
          font-size: 16px;
          line-height: 28px;
        }

        .kuro {
          color: #ffc02c;
          font-family: "DM sans", sans-serif;
          font-weight: 500;
        }
      }

      a {
        text-decoration: none;

        @media only screen and (max-width: 600px) {
          font-size: 12px !important;
        }
      }

      .launch-button {
        background: #0e1219;
        border: none;
        padding: .5rem 1rem;
        height: 2.5rem;
        border-radius: 5px;
        color: #fff8eb;
        margin-top: 1.5rem;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: #18163a;
          background: #fff8eb;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          font-size: .65rem;
          padding: 0;
          margin-left: 8px;
        }

        @media only screen and (max-width: 600px) {
          height: 2.6rem;
          ;
          margin-top: 1.5rem;
        }

      }

      .lauch-link {
        @media only screen and (max-width: 768px) {
          width: 100% !important;
        }

      }


      p.announcement-slab {
        a {
          display: flex;
          gap: 10px;
          align-items: center;
          margin-top: 40px;
          color: #0f1218;
          font-family: "Gilroy-Medium", arial, sans-serif;
          padding: .6rem .8rem;
          border-radius: 20px;
          font-size: 15px;
          background: #fbc1241a;
          width: 70%;

          @media only screen and (max-width: 768px) {
            width: 80%;
            flex-wrap: nowrap;
            white-space: pre;
          }

          i {
            background: rgb(251, 193, 24);
            color: #121212;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 10px;
          }
        }

      }


    }

    &-image {
      position: relative;

      &__chat,
      &__payment,
      &__book {
        position: absolute;
      }

      &__payment {
        top: 58px;
        z-index: 5000;
        right: -75px;
      }

      &__book {
        z-index: 5000;
        left: -18px;
        bottom: -33px;
      }


      img.placeholder {
        width: 87%;
        position: relative;
        left: 11px;
        z-index: 500;
      }
    }

    &-video {
      position: absolute;
      left: 88px;
      top: 106px;
      z-index: 5000;

      @media only screen and (max-width: 600px) {
        left: 41px;
        top: 44px;
      }

      video {
        width: 97%;
        border-radius: 8px;
        // margin-left: -206px;
        // -webkit-clip-path: inset(0% 24% 0% 31% round 41px);
        // clip-path: inset(0% 24% 0% 31% round 41px);
        z-index: 5000;
        position: absolute;
        left: 0;

        @media only screen and (max-width: 600px) {
          width: 92%;
        }

      }

      &__overlay {
        background: #00000026;
        position: absolute;
        height: 322px;
        width: 97%;
        z-index: 5000;
        top: 0;
        left: 0;
        right: 0;
        border-radius: 7px;

        @media only screen and (max-width: 600px) {
          height: 94%;
          width: 92%;
        }

      }
    }


    .banner-image {
      width: 100%;
      margin-top: 3rem;
      // position: absolute;
      top: 150px;
      // position: relative;

      @media only screen and (max-width: 600px) {
        // width: 93%;
        margin-top: 1rem;
        // top: 504px;
      }

      @media only screen and (min-width: 1480px) {
        width: 105%;
      }
    }


    .banner-video {
      margin-top: 4.5rem;
      position: relative;

      [class*="decor"] {
        position: absolute;

        @media only screen and (max-width: 720px) {
          display: none;
        }
      }

      .banner-video__decor {
        &__book {
          bottom: -39px;
          left: -22px;
          z-index: 500;
        }

        &__payment {
          top: -39px;
          right: -43px;
          z-index: 500;

          @media only screen and (max-width: 1100px) {
            display: none;
          }

          @media only screen and (min-width: 1400px) {
            right: -81px;
          }
        }

        &__scatter {
          top: -39px;
          right: -40px;
          z-index: 10;

          @media only screen and (max-width: 1100px) {
            display: none;
          }

          @media only screen and (min-width: 1400px) {
            right: -81px;
          }


          &-alt {
            bottom: -39px;
            left: -41px;
          }
        }

        &__shield {
          &-front {
            right: 60px;
            z-index: 600;
            bottom: -21px;
          }

          &-back {
            right: 72px;
            z-index: 40;
            bottom: -23px;
            width: 8%;
          }
        }

        &__play {
          bottom: 48%;
          width: 47px;
          left: 40px;
        }

        &__dot {
          width: 12%;
          left: 27px;
          top: -34px;
        }


      }

      &__wrap {
        background: #fff;
        width: 90%;
        height: 390px;
        border-radius: 14px;
        display: grid;
        grid-template-rows: 86% auto;
        margin-left: auto;
        z-index: 50;
        position: relative;
      }

      &__screen {
        margin: .5rem .5rem 0;
        // border: 1px solid #EBEFF6;
        border-radius: 14px;
        position: relative;

        video {
          object-fit: cover;
          width: 100%;
          position: relative;
          z-index: 1000;
          height: 100%;
          border-radius: 16px;
          aspect-ratio: 1 / 1;
        }

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 600;
          left: 0;
          border-radius: 16px;
        }

        &__overlay {
          background: rgba(0, 0, 0, .14901960784313725);
          position: absolute;
          width: 100%;
          z-index: 1200;
          top: 0;
          left: 0;
          right: 0;
          border-radius: 16px;
          height: 100%;
        }
      }



      &__thumbnails {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 5px;
        margin-top: -2px;

        &__item {
          width: 100px;
          height: 47px;
          // border: 1px solid #EBEFF6;
          border-radius: 5px;
          overflow: hidden;
          padding: .1rem;

          img {
            width: 100%;
            border-radius: 2px;
          }
        }

      }


    }




    &-partners {
      margin-top: 4.3rem;

      @media only screen and (max-width: 768px) {
        margin-top: 6.3rem;
      }

      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        margin-top: 1.2rem;
      }



      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        align-items: center;

        /* Portrait and Landscape */
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
          display: grid;
          grid-template-columns: repeat(4, minmax(120px, 1fr));
          grid-template-rows: 1fr;
          gap: 4.8rem;
        }

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5rem;

          /* Portrait and Landscape */
          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            margin: 0;
          }

          img {
            max-width: 145px;

            @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
              max-width: 120px;
            }
          }
        }
      }
    }
  }



  .section-two {
    .container {
      max-width: 1200px;
    }

    background: #fff;
    padding: 3rem 0 6rem;

    @media only screen and (max-width: 600px) {
      padding: 2rem 0 2rem;
    }

    .section-illustration {
      position: absolute;
      width: 30rem;
      margin-top: -3rem;

      @media only screen and (max-width: 600px) {
        // width: 20rem;
      }
    }
  }

  .section-three {
    background: #f6f6f6;
    // background: #fffaee #f0f3f85c;

    @media only screen and (max-width: 768px) {
      padding-top: 4rem;
    }
  }

  .section-four {
    padding: 6rem 0 7rem;

    @media only screen and (max-width: 768px) {
      padding: 1rem 0 4rem;
    }

  }

  .works {
    .container {
      max-width: 1200px;
    }

    h1 {
      margin-top: 3rem;
      font-weight: 500;
      color: #0e1219;
      letter-spacing: -0.01em;
      margin-bottom: 1.2rem;
      font-size: 3.2rem;
      max-width: 448px;
      line-height: 3rem;
      margin-bottom: 24px;
      letter-spacing: -0.4px;

      @media only screen and (max-width: 600px) {
        padding: 0 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.7rem;
      }
    }

    h5 {
      color: #0e1219;
      font-weight: 300;
      display: block;
      opacity: 0.85;
      max-width: 476px;
      font-size: 1.5rem;
      line-height: 1.7;


      @media only screen and (max-width: 600px) {
        padding: 1rem;
        font-size: 20px;
      }

      .kuro {
        font-weight: 500;
        font-family: "DM Sans";
      }
    }

    .sub {
      margin-top: 2rem;

      @media only screen and (max-width: 768px) {
        margin: 1rem 0;
      }

      &.one {
        margin-top: 7rem;

        @media only screen and (max-width: 768px) {
          margin-top: 3rem;
        }
      }

      // .features-image {
      //   position: relative;

      //   &::after {
      //     content: "";
      //     position: absolute;
      //     top: 0px;
      //     left: 0px;
      //     height: 100%;
      //     width: 100%;
      //     display: block;
      //     background-position: center center;
      //     background-size: contain;
      //     background-repeat: no-repeat;
      //     background-image: url('./assets/iphone_wrap.png');
      //   }

      //   &-wrap {
      //     position: relative;
      //   }
      // }



      .feat-slab {
        position: relative;
        display: flex;

        // &::after {
        //   content: url("./assets/feat-bg.svg");
        //   display: block;
        //   // background: #FFFAEE;
        //   // width: 515px;
        //   // height: 720px;
        //   position: absolute;
        //   top: -52px;
        //   left: -222px;

        //   @media only screen and (max-width: 600px) {
        //     display: none;
        //   }
        // }
      }

      .features {
        &-image {
          position: relative;
          display: flex;
          // height: 100%;
          mask-image: -webkit-radial-gradient(#fff, #000);

          img {
            width: 100%;
            height: 100%;
          }

          &-wrap {
            // width: 264px;
            // height: 533px;
            padding: 13px;
            background: #f6f9fc;
            box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
              0 30px 60px -30px rgba(0, 0, 0, 0.3),
              inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);
            -webkit-user-select: none;
            user-select: none;
            font-size: 16px;
            position: relative;
            width: 301px;
            height: 615px;
            border-radius: 42px;
            z-index: 100;
            margin-left: 15px;
            top: -30px;

            @media only screen and (max-width: 720px) {
              margin-left: initial;
              padding: 10px;
              overflow: hidden;
            }
          }
        }

        &-words {
          width: 92%;
          position: absolute;
          top: 350px;
          background: #fff;
          padding: 20px;
          height: 250px;
          border-radius: 10px 10px 30px 30px;

          @media only screen and (max-width: 720px) {
            width: 94%;
          }

          h3 {
            font-size: 16px;
            color: #34324e;
            line-height: 24px;
            margin-bottom: 0.5rem;
          }

          p {
            font-weight: 400;
            color: #34324ed9;
            font-size: 12px;
            opacity: 0.8;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            margin-right: 0.5rem;
          }

          &-user {
            display: flex;
            align-items: center;
            margin-bottom: 0.4rem;

            &-avatar {
              display: block;
              height: 30px;
              width: 30px;
              background: #eff2f7;
              border-radius: 50%;
            }

            &-name {
              margin-bottom: 0;
              font-weight: 400;
              margin-left: 5px;
            }
          }

          &-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 22px;

            button {
              width: 48%;
              border: none;
              outline: none;
              font-size: 13px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 5px 0;
              border-radius: 4px;
              color: #34324e;
              pointer-events: none;

              &.main {
                background: #ffc02c;
              }

              &.outline {
                border: 1px solid #dde4f0;
                background: transparent;
              }

              i {
                margin-right: 5px;
              }
            }
          }

          .link {
            color: #34324e;
            font-size: 13px;
            border: 1px solid #dde4f0;
            padding: 0.4rem 0.6rem;
            border-radius: 4px;
          }
        }
      }

      .card-img {
        width: 100%;

        @media only screen and (max-width: 720px) {
          margin: 0;
          padding: 0;
          width: 100%;
        }
      }

      .call-link {
        z-index: 500;
        /* margin: -60px 0 0; */
        display: flex;
        flex-direction: column;
        min-height: 620px;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
          min-height: initial;
          padding: 2rem !important;
          margin: 0;
        }

        i {
          background: #fff8e6;
          display: flex;
          width: 60px;
          height: 60px;
          justify-content: center;
          align-items: center;
          font-size: 25px;
          margin-bottom: 1rem;
          border-radius: 50%;
          color: #2c3548;

          &.alt {
            background: #0e1219;
          }

          img {
            width: 42%;
          }
        }

        h4 {
          color: #8c6c22;
          font-size: 22px;
        }

        h2 {
          color: #0e1219;
          margin-bottom: 0.8rem;
          font-size: 3.8rem;
          line-height: 1.2;
          letter-spacing: -0.07rem;
          font-family: 'Gilroy-Bold',
            sans-serif;

          @media only screen and (max-width: 600px) {
            font-size: 28px;
            margin-bottom: 0.5rem;
          }
        }


        p {
          opacity: 0.8;
          margin-top: 0.6rem;
          display: block;
          font-weight: 300;
          font-size: 1.1rem;
          line-height: 30px;
          color: #0e1219;
          max-width: 384px;
          margin-bottom: 25px;

          @media only screen and (max-width: 768px) {
            font-size: 18px;
            margin-top: 0.3rem;
            line-height: 1.7;
          }
        }

        &.one {
          margin-left: 1rem;
        }
      }

      .landing-steps {
        color: #0e1219;
        list-style: none;
        padding: 0;
        margin: 0;

        &__one {
          @media only screen and (max-width: 600px) {
            margin-top: 3rem;
          }
        }

        li {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.6;
          margin-bottom: 1.1rem;

          &.faded {
            opacity: .2;
            color: #0e1219;
          }

          &.complete {
            text-decoration: line-through;
          }
        }

        &__paragraph {
          @media only screen and (max-width: 768px) {
            margin-bottom: 3.5rem;
          }
        }
      }

      .feat-wrap {
        background: #fff8e6;
        border-radius: 14px;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        min-height: 660px;
        // overflow: hidden;
      }

      .features-video {
        position: relative;

        video {
          width: 641px;
          border-radius: 25px;
          margin-left: -206px;
          -webkit-clip-path: inset(0% 24% 0% 31% round 41px);
          clip-path: inset(0% 24% 0% 31% round 41px);
        }

        &__overlay {
          background: #0000001f;
          position: absolute;
          height: 500px;
          width: 274px;
          z-index: 5000;
          top: 0;
          left: 0;
          right: 0;
          border-radius: 25px;
        }
      }

      .started-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10rem;
        background: #0f1218;
        color: #fff8eb;
        height: 3rem;
        border-radius: 6px;
        font-size: 1rem;

        i {
          background: #fff8e6;
          display: flex;
          width: 20px;
          height: 20px;
          font-size: 12px;
          margin-left: 1rem;
          margin-bottom: 0;
        }
      }

      .lady {

        &-hand,
        &-finger {
          @media only screen and (max-width: 768px) {
            display: none;
          }

        }

        &-hand {
          position: absolute;
          width: 134%;
          left: -214px;
          top: 127px;
          // clip-path: inset(5% 13% 26%);
          clip-path: inset(2% -10% 26%);

        }

        &-finger {
          position: absolute;
          z-index: 5000;
          left: 354px;
          bottom: 13px;

          @media only screen and (max-width: 1024px) {
            left: 299px;
          }
        }
      }

      .payment-slip {

        &-wrap {
          z-index: 5000;
          margin-top: -8px;
        }


        @media only screen and (max-width: 720px) {
          width: 100%;
        }

      }



      &.two {
        padding: 5.3rem 0 7rem;

        @media only screen and (max-width: 600px) {
          padding: 0;
        }

      }

      &.three {
        padding: 5rem 3rem;

        @media only screen and (max-width: 600px) {
          padding: 0;
        }

        .paid-tab {
          @media only screen and (max-width: 600px) {
            padding: 1.5rem;
            width: 25rem;
          }
        }
      }
    }
  }

  .section-five {
    padding: 2rem 0 5rem;
    margin-top: -5rem;

    @media only screen and (max-width: 600px) {
      padding: 0;
      margin-top: initial;
    }

    .container {
      max-width: 1200px;
    }

    h3 {
      margin-top: 2rem;
      margin-bottom: 4rem;
      font-size: 26px;
      line-height: 159%;
      font-weight: 500;
      letter-spacing: 0.01em;
      color: #0e1219;

      @media only screen and (max-width: 600px) {
        font-size: 24px;
        padding: 0rem 1rem;
      }
    }

    .handle-notes {
      margin-top: 2rem;

      @media only screen and (max-width: 600px) {
        padding: 1rem;
      }

      div {
        padding-bottom: 3rem;

        img {
          width: 3rem;
        }

        h5 {
          font-weight: 500;
          margin-top: 1rem;
          margin-bottom: 1rem;
          font-size: 1.3rem;
          color: #0e1219;
        }

        p {
          font-size: 17px;
          font-weight: 300;
          max-width: 25rem;
          line-height: 28px;
          color: #0e1219;
          opacity: 0.8;

          @media only screen and (max-width: 600px) {
            width: 17rem;
          }
        }
      }
    }
  }

  .head-banner {
    background: #0e1219 url(../src/assets/dots.png);
    padding: 2rem 0 3rem;

    &__text {
      margin-left: 4rem;

      @media only screen and (max-width: 720px) {
        margin-left: 0;
      }

      h1 {
        color: #fff;
        line-height: 45px;
        margin-bottom: 1.4rem;
        font-size: 2.6rem;
        max-width: 445px;

        @media only screen and (max-width: 720px) {
          max-width: initial;
          font-size: 2.8rem;
          margin-bottom: 1.1rem;
        }

        span {
          color: #ffe8bd;
        }
      }

      h5 {
        color: #fff;
        display: block;
        opacity: .78;
        font-size: .9rem;
        line-height: 32px;
        letter-spacing: 0.4px;
        font-family: "Gilroy-Regular",
          sans-serif;
      }

      .buttons {
        margin-top: 2rem;
        justify-content: flex-start !important;

        a {
          text-decoration: none;

        }

        button {
          width: 10rem
        }

      }

    }
  }

  .word-mark {
    margin-bottom: 6rem;
    position: relative;

    @media only screen and (max-width: 768px) {
      padding: 1rem;
      margin-bottom: 4rem;
    }


    h2 {
      font-size: 2rem;
      line-height: 48px;
      margin-bottom: 24px;
      max-width: 500px;
      color: #0e1219;
      font-family: "Gilroy-bold",
        sans-serif;

      @media only screen and (max-width: 768px) {
        font-size: 2rem;
        line-height: 44px;
        text-align: left;
      }

      /* Portrait and Landscape */
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        text-align: center;
      }

    }

    h6 {
      font-size: 18px;
      line-height: 36px;
      margin-bottom: 24px;
      font-weight: 300;
      max-width: 680px;
      color: #0e1219;

      @media only screen and (max-width: 720px) {
        text-align: left;
      }
    }

    &::after {
      content: url("./assets/divider.svg");
      display: flex;
      position: absolute;
      left: 37%;
      bottom: -28px;

      @media only screen and (max-width: 600px) {
        left: 6%;
        bottom: 11px;
      }
    }
  }

  &.ja {

    h2,
    h5,
    p {
      word-break: break-all;
    }

    .section-one {
      .section-one-text {
        h6 {
          margin-bottom: 25px;
          color: #0e1219;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: -0.001rem;
          text-transform: uppercase;
          font-family: 'Gilroy-Medium',
            sans-serif;

          @media only screen and (max-width: 768px) {
            margin-bottom: 10px;
            font-size: 15px;
            line-height: 1.2;
          }
        }

        h1 {
          line-height: 60px;
          font-size: 32px;
          word-break: break-all;

          span {
            box-shadow: #ffedc3 0px -17px 0px inset;
          }

          @media only screen and (max-width: 1029px) {
            font-size: 38px;
          }

          @media only screen and (max-width: 768px) {
            font-size: 27px;
            line-height: 48px;
            margin-top: 20px;
            margin-bottom: 15px;
          }
        }

        .kurobi-text {
          font-size: 16px;
          line-height: 36px;
          word-break: break-all;

          @media only screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 28px;
          }
        }



      }
    }


    .word-mark {
      h2 {
        word-wrap: anywhere;
      }

      h6 {
        word-wrap: anywhere;
      }
    }

    .head-banner {
      &__text {
        .buttons {
          button {
            width: 14rem
          }
        }
      }
    }

  }
}


@-webkit-keyframes banner-header-text {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1.17em);
    transform: translateY(1.17em);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes banner-header-text {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1.17em);
    transform: translateY(1.17em);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes banner-body-text {
  0% {
    opacity: 0;
    -webkit-transform: translateY(5.063em);
    transform: translateY(5.063em);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes banner-body-text {
  0% {
    opacity: 0;
    -webkit-transform: translateY(5.063em);
    transform: translateY(5.063em);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}



.animated-banner-header-text {
  position: relative;
  transform: translateY(1.17em);
  // display: inline-block;
  -webkit-animation: banner-header-text 0.618s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  animation: banner-header-text 0.618s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
  transition: all 500ms cubic-bezier(0.4, 0.2, 0.3, 1) 0ms;
}

.animated-banner-body-text {
  position: relative;
  // display: inline-block;
  -webkit-transform: translateY(5.063em);
  transform: translateY(5.063em);
  -webkit-animation: banner-body-text 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 2.118s forwards;
  animation: banner-body-text 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 2.118s forwards;
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
  transition: all 500ms cubic-bezier(0.4, 0.2, 0.3, 1) 0ms;
}

.poster {
  &-one {
    position: absolute;
    right: -35px;
    top: -114px;
  }

  &-two {
    position: absolute;
    bottom: -22px;
    left: -23px;
  }
}